

.map-sect{
    height: 100%;
    width: 100%;
    padding: 1rem;
    background-color: #535e7a;
}
.btn{
    padding: 1rem 2rem;
    background-color: #037FFF;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 2px;
    margin-top: 1rem;
    position: relative;
    &::after{
        position: absolute;
        content: "";
        width: 0;
        height: .3rem;
        left: 0;
        bottom: 0;
        background-color: #a4acc4;;
        transition: all 0.4s ease-in-out;
    }
    &:hover::after{
        width: 100%;
    }
}

